import React, { Component } from 'react';

import VerticalLightDimmer from '../controller/VerticalLightDimmer';
import BinarySwitch from '../controller/BinarySwitch';
import ModalContainer from '../ModalContainer';


class RowLight extends Component {
	render() {
		if (this.props.dim&&this.props.statusDim){
			return (
				<>
					<ModalContainer className="table-row ease-02">
						<div className="table-col" data-modal-trigger>{this.props.label}</div>
						<div className="table-col text-right" data-modal-trigger></div>
						<div className="table-col text-right">
							<BinarySwitch {...this.props} />
						</div>
						<div className="flex-wrapper col" data-modal-content>
							<h1 className="text-center">{this.props.label}</h1>
					    	<VerticalLightDimmer dim={this.props.statusDim} io={this.props.ga} />
						</div>
					</ModalContainer>
				</>	
			);			
		} else {
			return (
				<div className="table-row ease-02">
					<div className="table-col">{this.props.label}</div>
					<div className="table-col text-right" >
						<BinarySwitch {...this.props} />
					</div>
				</div>
			);	
		}
	}
}

export default RowLight;