import React, { Component } from 'react';
import {DataContext} from '../DataContext';

class NoConnection extends Component {
	static contextType = DataContext
	render() {
		var socketCon = this.context.socketConnection ? ' socket-connected' : '';
		var busCon = Object.getOwnPropertyNames(this.context.ga).length >= 1 ? ' bus-connected' : '';
		if(socketCon && busCon) return (<></>);
		return (
			
			<div className={"ease-02 no-connection"+socketCon+busCon}>
				<div className="wrapper">
					<div className="loading-screen"><div className="loader"></div></div>
					<div className="content">
					{(socketCon) 	? '' : <div className="socket text-center">Verbinde zum Server</div> }
					{(busCon) 		? '' : <div className="socket text-center">Verbinde zum KNX-Bus</div> }
					</div>
				</div>
			</div>
		);
	}
}

export default NoConnection;