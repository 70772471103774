import * as React from 'react';

import { Range, Direction } from 'react-range';
import { DataContext } from '../../DataContext';

const STEP = 0.1;
const MIN = 0;
const MAX = 100;

class VerticalLightDimmer extends React.Component {
	static contextType = DataContext;
	_isMounted = false;
	id = 'id'
	state = {values: [0]};
	targetElement = null;
	bodyScroll = true
	currentDimValue = null;
	constructor(props, context) {
		super(props, context);	
		this.onChange = this.onChange.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
	}

	onChange(values){
		this.setState({ values })
	}
	onUpdate(values){
		if (this._isMounted) {
			this.context.setPercentAbsolute({ga:this.props.dim, value: values[0]});
			this.currentDimValue = this.context.getNumericValue(this.props.dim,0);
		}
	}
	UNSAFE_componentWillMount() {
		this.id = "random-id-" + (Math.floor(Math.random() * (999999 - 1)) + 1);
		let value = this.context.getNumericValue(this.props.dim,0);
		this.currentDimValue = value;
		this.setState({ values:[value] })		
	}
	componentDidMount() {
		this.targetElement = document.querySelector('#'+this.id);
		this._isMounted = true;
	}
	UNSAFE_componentWillUpdate() {
		let value = this.context.getNumericValue(this.props.dim,0);
		if(value !== this.currentDimValue){
			this.currentDimValue = value;
			this.setState({ values:[value] })
		}	
	}
	componentWillUnmount() {
		this._isMounted = false;
	}  
	
	render() {
	    return (
			<div className="range-slider-vertical" id={this.id} >
				<Range
					direction={Direction.Up}
					step={STEP}
					min={MIN}
					max={MAX}
					values={this.state.values}
				    onChange={this.onChange}
				    onFinalChange={this.onUpdate}
				    renderTrack={({ props, children }) => (
				        <div
				        	{...props}
				        	className="range-slider-track"
							>
							<div className="range-slider-rail-active" style={{
								height: this.state.values[0] + "%",
								opacity: this.state.values[0]/100,
								}} />
							<div ref={props.ref} className="range-slider-rail">
								{children}
							</div>
				        </div>
				    )}
				    renderThumb={({ props, isDragged }) => (
						<div
							{...props}
							className="range-slider-handle"
							style={{
								...props.style,
								bottom: this.state.values[0] + "%"		            
							}} 
							>
							<div className={isDragged ? "range-slider-handle-drag dragged" : "range-slider-handle-drag"} />		
						</div>
				    )}
				/>
		        {
					<output className="range-slider-output">
						{this.state.values[0].toFixed(0)}<span>%</span>
					</output>
		        }	      
		      
	      </div>
	    );
	}
}
export default VerticalLightDimmer;