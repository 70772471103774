
import React, { Component } from 'react';
import {DataContext} from '../DataContext';
// import MemoImage from '../components/layout/MemoImage';

class User extends Component {
	static contextType = DataContext
    constructor(props, context){
        super(props, context);
	    this.logout = this.logout.bind(this);
		this.state = {
			newPasswordLength:false,
			newPassword: false,
			oldPasswordLenght: false,
			oldPassword: false,
			messagePassword: false,
			messagePasswordType: 'msg-error',
		}
		this.context.socket.on('PASSWORD_CHANGED', function(data){
			if(data.changed){
				this.setState(state => ({messagePassword:data.msg}))
				this.setState(state => ({oldPasswordLength:false}));
				this.setState(state => ({newPasswordLength:false}));
				this.setState(state => ({oldPassword:false}));
				this.setState(state => ({messagePasswordType:'msg-success'}));
			} else {
				this.setState(state => ({messagePassword:"Fehlercode " + data.error + ": " + data.msg}))
			}
		}.bind(this));		
		
	}
	componentDidMount() {
		document.title = "Profil von " + this.context.user.name;
		this.context.getServerVersion();
	}
	logout() {
		this.context.logout();
	}
	changePassword() {
		this.context.changePassword({
			newPassword: this.state.newPassword,
			oldPassword: this.state.oldPassword,
			uid:  this.context.user.uid,
		});
	}
	handleChange (e, type = 'new') {
		if(e.target.value.length === 5){
			let newPassword = e.target.value;
			if(type === 'old'){
				this.setState(state => ({oldPasswordLength:true}))
				this.setState(state => ({oldPassword:newPassword}))
			}
			if(type === 'new'){
				this.setState(state => ({newPasswordLength:true}))
				this.setState(state => ({newPassword:newPassword}))
			}
		} else {
			if(type === 'new'){
				this.setState(state => ({newPasswordLength:false}))
				this.setState(state => ({newPassword:false}))
			}
			if(type === 'old'){
				this.setState(state => ({oldPasswordLength:false}))
				this.setState(state => ({oldPassword:false}))
			}
		};

	}	
	
	render() {
		let picture = this.context.user.picture;
		return (
		    <div className="body-content base-padding-v">
		    	
		    	
		    	<div className="profile-picture big block-center ease-02 cover" style={{backgroundImage:"url('"+picture+"')"}}></div>	
		    	
		    	<h1 className="text-center base-padding-v">{(this.context.user&&this.context.user.name)? <>{this.context.user.name}</>:<>noName</>}</h1>
				<div className="content-container base-padding">
					<div className="base-padding">				
						<button className="btn btn-default btn-block block-center" onClick={() => {this.logout()}}>Logout</button>
					</div>
					
					<h2 className="text-center">Passwort ändern</h2>
					{this.state.messagePassword ? <div className={"msg text-center minus-base-padding-h " + this.state.messagePasswordType }>{this.state.messagePassword}</div>:<>
						<div className="change-passwort text-center">
							<h3 className="text-center">altes Passwort</h3>
							<input className="login visible ease-02" maxLength="5" onChange={(e) => {this.handleChange(e, 'old')}}/>
							{this.state.oldPasswordLength ?
								<>
									<h3 className="text-center">neues Passwort</h3>
									<input className="login visible ease-02" maxLength="5" onChange={(e) => {this.handleChange(e)}}/>
									{this.state.newPasswordLength ?<div className="text-center base-padding-v"><button className="btn btn-default" onClick={() => {this.changePassword()}}>Passwort ändern</button></div>: <></>}
								</>
							: <></>}
						</div>
					</>}
				</div>

		
								
				<div className="base-padding info">
					<small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small><br/>
					<small>Server Version: {this.context.serverVersion}</small><br/>
					<small>Client Version: {this.context.clientVersion} | {process.env.NODE_ENV}</small><br/>
					<small>{process.env.REACT_APP_ADDRESS+":"+process.env.REACT_APP_PORT}</small>
				</div>
				
				
		    </div>
		);
	}
}

export default User;
