
import React, { Component } from 'react';
import {DataContext} from '../../DataContext';
//import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {  Pagination } from 'swiper';
import WidgetIO from '../../components/widgets/WidgetIO';



SwiperCore.use([Pagination]);


class Slider extends Component {
	static contextType = DataContext;
	visible = 'visible';
	constructor(props, context) {
		super(props, context);
		this.state = { 
			items: this.props.content, 
		};
	}
	render() {
		let items = this.state.items.map(function(item, i) {
			let visible = item.props.visible === undefined ? this.props.visible : item.props.visible;
			
			item.props.status = this.context.getBinaryValue(item.props.ga, false);
			
			switch(visible){
				case 'off': if (!item.props.status) {return false};break;
				case 'on': if (!item.props.status) {return false}; break;
				default: break;
			}
			switch(item.type){
				case 'WidgetIO': return(<SwiperSlide key={i}> <WidgetIO {...item.props} /></SwiperSlide>);
				default:break;
			}
			return	(<SwiperSlide key={i}> {item.type} : {item.props.label} {item.props.visible}</SwiperSlide>)	

    	}.bind(this));
		items = items.filter(Boolean);
		if(this.props.sort === 'status'){
			items.sort(function(a, b) {
			    var A = a.props.children[1].props.status ? 0 : 1;
			    var B = b.props.children[1].props.status ? 0 : 1;
			    return (A < B) ? -1 : (A > B) ? 1 : 0;
			});
		}

		if(items.length === 0) return (<></>);
		return (
		    <div className="component-slider">
				<div className="content-label">{this.props.label}</div>
				    <Swiper
				    	{...this.props.swiper}
				//		className="mode-auto with-pagination"
				//		spaceBetween={25}
				//		slidesPerView={'auto'}
				//		freeMode={true}
//						centeredSlides={true}
//						pagination={{ clickable: true }}
//						onSlideChange={() => console.log('slide change')}
//						onSwiper={(swiper) => console.log(swiper)}
				    >
				    
						{items}
				    
				    
				    
				    
				    	{this.props.children}
				    </Swiper>	
		    </div>
		);
	}
}

export default Slider;
