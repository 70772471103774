import * as React from 'react';

import { Range, Direction } from 'react-range';
import { DataContext } from '../../DataContext';
import Loader from '../Loader';

const STEP = 0.1;
const MIN = 0;
const MAX = 100;

class VerticalJal extends React.Component {
	static contextType = DataContext;
	_isMounted = false;
	id = 'id'
	state = {values: [0]};
	targetElement = null;
	bodyScroll = true
	currentPositionValue = null;
	constructor(props, context) {
		super(props, context);	
		this.onChange = this.onChange.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.jalStop = this.jalStop.bind(this);
	}

	onChange(values){
		this.setState({ values })
	}
	onUpdate(values){
		if (this._isMounted) {
			//this.context.setPercentAbsolute({ga:this.props.dim, value: values[0]});
			this.context.jalControler({ga:this.props.ga.position, op:'position', value: values[0]});
			this.currentPositionValue = this.context.getNumericValue(this.props.ga.position, 0);
		}
	}
	UNSAFE_componentWillMount() {
	//	this.id = "random-id-" + (Math.floor(Math.random() * (999999 - 1)) + 1);
		let value = this.context.getNumericValue(this.props.ga.position, 0);
		this.currentPositionValue = value;
		this.setState({ values:[value] })		
	}
	
	componentDidMount() {
		//this.targetElement = document.querySelector('#'+this.id);
		this._isMounted = true;
	}
	
	UNSAFE_componentWillUpdate() {
		let value = this.context.getNumericValue(this.props.ga.position,0);
		if(value !== this.currentPositionValue){
			this.currentPositionValue = value;
			this.setState({ values:[value] })
		}	
	}
	componentWillUnmount() {
		this._isMounted = false;
	}  

	jalStop() {
		this.context.jalControler({ga:this.props.ga.position,op:'stop'});
	}

	
	render() {
		
		const activeUp 			= this.context.getBinaryValue(this.props.ga.activeUp, null);
		const activeDown 		= this.context.getBinaryValue(this.props.ga.activeDown, null);
		let isActive 			= ((typeof activeUp === "boolean"&&activeUp)||(typeof activeDown === "boolean"&&activeDown)) ? true : false;
		//isActive = true;
		let disabledClass		= isActive ? ' range-slider-disabled' : '';
	    return (
			<div className={'range-slider-vertical range-slider-jal' + disabledClass } >
				
				<div className="stop-wrapper" onClick={this.jalStop}><i className="icon-pause"></i></div>
								
				<Range
					disabled={isActive}
					direction={Direction.Down}
					step={STEP}
					min={MIN}
					max={MAX}
					values={this.state.values}
				    onChange={this.onChange}
				    onFinalChange={this.onUpdate}
				    renderTrack={({ props, children }) => (
				        <div
				        	{...props}
				        	className="range-slider-track"
							>
							<div className="range-slider-rail-active" style={{
								height: this.state.values[0] + "%",
								backgroundPositionX: this.state.values[0] + "%",
								
								}} />
							<div ref={props.ref} className="range-slider-rail">
								{children}
							</div>
				        </div>
				    )}
				    renderThumb={({ props, isDragged }) => (
						<div
							{...props}
							className="range-slider-handle"
							style={{
								...props.style,
								top: this.state.values[0] + "%"		            
							}} 
							>
							<div className={isDragged ? "range-slider-handle-drag dragged" : "range-slider-handle-drag"} />		
						</div>
				    )}
				/>
		        {
					<output className="range-slider-output">
						{this.state.values[0].toFixed(0)}<span>%</span>
					</output>
		        }	      
		      
	      </div>
	    );
	}
}
export default VerticalJal;