import * as React from 'react';

import { Range, Direction } from 'react-range';
import { DataContext } from '../../DataContext';

const STEP = 0.5;
const MIN = 15;
const MAX = 25;
/*
const COLOR_MIN = {
	r:0, g: 70, b:150,
};
const COLOR_MAX= {
	r:240, g: 120, b:0,
};
*/
const COLORGRADIANTS = [
		{to: 30, color1:'#000fff', color2:'#123456'},
		{to: 40, color1:'#44ff44', color2:'#555555'},
		{to: 80, color1:'#fff000', color2:'#555555'}
	];
	


class VerticalHeater extends React.Component {
	static contextType = DataContext;
	_isMounted = false;
	id = 'id'
	state = {values: [0], percent:0, color: "0,0,0"};
	targetElement = null;
	bodyScroll = true;
	currentValue = null;
//	currentColorIndex: 0;
	percent = 0;
	ease = function (t){
		//https://gist.github.com/gre/1650294
		return  t<.5 ? 16*t*t*t*t*t : 1+16*(--t)*t*t*t*t;
	}

	calcColor = function (value){
		let rIndex = 0;
		COLORGRADIANTS.forEach(function (value, index) {
		    if(value.to <= this.percent){
			    if(typeof COLORGRADIANTS[index+1] !== 'undefined' && COLORGRADIANTS[index+1].to <= this.percent){
					rIndex = index+1;
   
			    } else {
					rIndex = index;		
			    }
		    }
		}.bind(this));
		
		
		
		
		return COLORGRADIANTS[rIndex].color1;
		// return "linear-gradient(15deg, "+COLORGRADIANTS[rIndex].color1+" 0%, "+COLORGRADIANTS[rIndex].color1+" 35%, "+COLORGRADIANTS[rIndex].color2+" 100%)";

		
		
		
		//;
		// return this.calcColorChanel(COLOR_MIN.r, COLOR_MAX.r) + "," + this.calcColorChanel(COLOR_MIN.g, COLOR_MAX.g) + "," + this.calcColorChanel(COLOR_MIN.b, COLOR_MAX.b);
	};/*
	calcColorChanel = function (v1, v2){
		if(v1 <= v2){
			 return Math.round( v1 + ((v2 - v1) / 100 * ( this.ease (this.percent/100 )*100) )  );
		} else {
			 return Math.round( v1 - ((255 - v2) / 100 * ( this.ease (this.percent/100 )*100) ) );
		}
	};
	*/

	
	
	calcPercent = function (value){
		return ((value - MIN)/(MAX-MIN)*100 )
	};
	constructor(props, context) {
		super(props, context);	
		this.onChange = this.onChange.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
	}


	onChange(values){
		this.percent = this.calcPercent(values[0]);
		this.setState({ 
			values, 
			percent: this.percent,
			color: this.calcColor(values[0])
		})
	}
	onUpdate(values){
		if (this._isMounted) {
			this.context.setGa({ga:this.props.writer, value: values[0]});
			this.currentValue = this.context.getGaValue(this.props.reader,MIN);
		}
	}
	UNSAFE_componentWillMount() {
		this.id = "random-id-" + (Math.floor(Math.random() * (999999 - 1)) + 1);
		let value = this.context.getGaValue(this.props.reader,MIN);
		this.currentValue = value;
		this.percent = this.calcPercent(value);

		this.setState({ 
			values:[value], 
			percent: this.percent,
			color: this.calcColor(value)
		})	
	}
	componentDidMount() {
		this.targetElement = document.querySelector('#'+this.id);
		this._isMounted = true;
	}
	UNSAFE_componentWillUpdate() {
		let value = this.context.getGaValue(this.props.reader,MIN);
		if(value !== this.currentValue){
			this.percent = this.calcPercent(value);

			this.currentValue = value;
			this.setState({ 
				values:[value],
				percent: this.percent,
				color: this.calcColor(value)
			})
		}	
	}
	componentWillUnmount() {
		this._isMounted = false;
	}  
	
	render() {
		
		console.log(this.state.color);
		
	    return (
      <div className="range-slider-vertical range-slider-heater" id={this.id}>
        <Range
          direction={Direction.Up}
          values={this.state.values}
          step={STEP}
          min={MIN}
          max={MAX}

	    onChange={this.onChange}
	    onFinalChange={this.onUpdate}
          renderMark={({ props, index }) => (
            <div
              {...props}
              className={`marker marker-${index % 5 ? "-small" : "-large"} marker-bg-color-${index * STEP > MAX - this.state.values[0] ? "active" : "not-active"} marker-index-${index} ${index === 0 ? "first" : ""} ${index * STEP === MAX-MIN ? "last" : ""}`}
              style={{
                ...props.style
              }}
            >
				{index % 5 
					? ''
					: <div className="marker-label marker-big">{parseFloat(MAX - index * STEP).toFixed(1)}°</div>
				}
            </div>
          )}
          renderTrack={({ props, children }) => (

            <div
	        	className="range-slider-track"
				onMouseDown={props.onMouseDown}
				onTouchStart={props.onTouchStart}
				style={{
					...props.style,
				}}
				>
				<div className="range-slider-rail-active" 
					style={{
						height: this.state.percent + "%",
						opacity: this.state.percent / 1,
						background: this.state.color
					}} >					
					</div>
				<div ref={props.ref} className="range-slider-rail">
					{children}
				</div>
	        </div>
            
            
            
          )}
          renderThumb={({ props, isDragged }) => (
 
 			<div
				{...props}
				className="range-slider-handle"
				style={{
					...props.style,
				}} 
			>
				<div className="range-slider-drag-label">{this.state.values[0].toFixed(1)}°</div>
				<div className={isDragged ? "range-slider-handle-drag dragged" : "range-slider-handle-drag"} />		
			</div>
          )}
        />
        {
          <output id="output">
          		<div className="heater-setpoint"> {this.context.getTempValue(this.props.reader, '--')}</div>
          </output>
        }
      </div>
	    );
	}
}
export default VerticalHeater;