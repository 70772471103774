import React, { Component } from 'react';
import {DataContext} from '../DataContext';
import Loader from '../components/Loader';
import userAgentParser from 'ua-parser-js';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

class Login extends Component {
	  _isMounted = false;
	static contextType = DataContext
    constructor(props, context){
        super(props, context);
	    this.state = {
		    fingerprint: false,
			activeUser: false,
			forgotPasswort:false,
			user:[]};
		this.forgotPassword = this.forgotPassword.bind(this)	
		this.context.socket.emit('USER_LIST', {list:'all'} );
		this.context.socket.on('USER_LIST', function(data){
			if(this._isMounted) this.setState(state => ({user:data.user}))
		}.bind(this));
		this.context.socket.on('WRONG_PASSWORD', function(data){
			if(this._isMounted) this.setState(state => ({forgotPasswort:true}))
		}.bind(this));
    }	
	
	componentDidMount() {
	    this._isMounted = true;
		document.title = 'Login';
	}
	componentWillUnmount() {
		this._isMounted = false;
	}

	handleChange (e) {
		if(e.target.value.length === 5){
			let userAgentData = userAgentParser(navigator.userAgent);
			let password = e.target.value;
						console.log(userAgentData.device);

			FingerprintJS.load().then(function(fp){
			    fp.get().then(function (result){
			        const visitorId = result.visitorId;
			        this.context.sendAuth({pass:password, uid:this.state.activeUser, userAgent:userAgentData, fingerprint: visitorId});
			    }.bind(this));
			}.bind(this))
		};
	}
	toggleUser (e, uid) {
		if(this.state.activeUser === uid) uid = false;
		this.setState(state => ({activeUser:uid}))
	}
	forgotPassword () {
		if(this.state.activeUser){
			this.context.socket.emit('FORGOT_PASSWORD', {uid:this.state.activeUser} );
			this.setState(state => ({forgotPasswort:false}));
		}
	}
	render() {
		return (
		    <div className="login-body">
		    	<div className="wrapper ">
		    		<div className="logo-wrapper text-center"><i className="icon-home-smart-home"></i></div>
		    		<div className={(this.state.activeUser)?"profile-wrapper has-active":"profile-wrapper"}>
		    		       {this.state.user.length === 0 ? <Loader />:<>
			    		       {this.state.user.map((user, i) => {     
						           var activeClass = user.uid === this.state.activeUser ? ' active' : '';
						           return (
								    <div className={"profile-picture login ease-02 cover " + activeClass} key={i} data-profile={user.uid} style={{backgroundImage: "url('"+user.picture+"')"}} onClick={(e) => {this.toggleUser(e, user.uid)}}>
					    				<span className="full-name">{user.name}</span>
					    			</div>) 
						        })}
					        
					        </>}
		    		</div>
					<input className={(this.state.activeUser)? "login visible ease-02":"login hidden ease-02"} maxLength="5" onChange={(e) => {this.handleChange(e)}}/>
					{this.state.activeUser&&this.state.forgotPasswort?<><div><span className="small" onClick={this.forgotPassword}>Passwort vergessen?</span></div></>:<></>}
					
				</div>
		    </div>
		);
	}
}

export default Login;
