
import React, { Component } from 'react';
import {DataContext} from '../../DataContext';

class ContentGrid extends Component {
	static contextType = DataContext

	render() {
		return (
		    <div className="content-grid">
				<div className="content-label">{this.props.label}</div>
				<div className="css-grid">
					{this.props.children}
				</div>
		    </div>
		);
	}
}

export default ContentGrid;
