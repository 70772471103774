import './assets/styles/scss/app.scss';
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, NavLink, Redirect} from 'react-router-dom';
import Login from './pages/Login';
// import Shadow from './pages/Shadow';
import User from './pages/User';
import Page from './pages/Page';
import NoConnection from './pages/NoConnection';
import {DataContext} from './DataContext';
import WidgetWeatherHeader from './components/widgets/WidgetWeatherHeader';
import WidgetLuxHeader from './components/widgets/WidgetLuxHeader';
import WidgetLunarHeader from './components/widgets/WidgetLunarHeader';

// https://infinum.com/the-capsized-eight/how-to-use-react-hooks-in-class-components#solution



class App extends Component {
//	const location = useLocation();
	static contextType = DataContext;

	constructor(props) {

		super(props);
		this.state = {
			isOpen: false
		}
		this.menuToggle = this.menuToggle.bind(this);

	}
	

	
	menuToggle(e, val = null) {
		e.stopPropagation();
		this.setState({
			isOpen: typeof val === 'boolean' ? val : !this.state.isOpen,
		});
	}	
	
	componentDidMount() {
		document.body.classList.add(this.context.theme);
	}
	

	render() {
		//const { match, location, history } = this.props

		
		//let location = useLocation();
		//console.log(location);
		//console.log(this.props);
		
		if( this.state.isOpen ) {
			document.body.classList.add('nav-open');
		} else {
			document.body.classList.remove('nav-open');
		}

		
		if(!this.context.isAuth) return <Login />
		
		
	    return (
			<Router>
		        <div>
					<div className="header">
							<button className="hamburger hamburger--collapse" type="button" onClick={(e) => {this.menuToggle(e)}}>
								<span className="hamburger-box">
									<span className="hamburger-inner"></span>
								</span>
							</button>
							<div className="right widget widget-header">
								<WidgetLuxHeader/>
								<WidgetLunarHeader/>
								<WidgetWeatherHeader/>
							</div>
					</div>
					<nav className="navbar">
						<ul className="navbar-nav">
							{this.context.menu.navbar.map((link, i) => { 
								let dynamicProps = link.props ? link.props : {};
								let label = link.icon ? <i className={link.icon} /> : link.label;
							   return (<li className="nav-item" key={i}><NavLink to={link.path} className="nav-link" activeClassName="active" onClick={(e) => {this.menuToggle(e,false)}} {...dynamicProps} >
							   {label}
							   </NavLink></li>) 
							})}
						</ul>
					</nav>
					
						<div className="slide-menu-container">
							<div className="slide-menu">
								<div className="slide-menu-content">
								
								
									<div className="menu-logo-wrapper text-center">
										<i className="icon-home-smart-home"></i>
									</div>
								
									<ul className="slide-menu-nav">
										{this.context.menu.main.map((link, i) => { 
											let dynamicProps = link.props ? link.props : {};
										   return (<li className="nav-item" key={i}><NavLink to={link.path} className="nav-link" activeClassName="active" onClick={(e) => {this.menuToggle(e,false)}} {...dynamicProps} >{link.label}</NavLink></li>) 
										})}
									</ul>
								</div>
							</div>
							<div className="backdrop" onClick={(e) => {this.menuToggle(e,false)}}></div>
					</div>
					
					
					<div id="content-container">
					<Switch>
						<Route path='/konto' component={User} />
						<Route path='/:id' component={Page} />
						<Route exact path="/" component={() => <Redirect to="/dashboard" />}/>
					</Switch>
					</div>

					<NoConnection />

		        </div>
			</Router>
	    );
	}
}

export default App;

