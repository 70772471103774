import React, { Component } from 'react';
import {DataContext} from '../../DataContext';
import Loader from '../Loader';

class WidgetLuxHeader extends Component {
	static contextType = DataContext
	constructor() {
		super();
		this.state = { 
			checked: false, 
			disabled: false,
		};

	}
	render() {
		let lux 			= Math.round(this.context.getGaValue('6/5/7','none')); //MAKE IT DYNAMIC
		if(!isNaN(lux)){
			return (
				<div className="widget-lux-header widget-componenent-header text-right on flex">
					<div className="value">{lux} lx</div>
					{(typeof this.context.getBinaryValue('6/5/5')==="boolean") 
						? this.context.getBinaryValue('6/5/5', false) 
							? <span className={"status-dot status-on"}></span> 
							: <span className={"status-dot status-off"}></span>
					: <></>}

				</div>
			)
		} else {
			return (
				<div className="widget-lux-header widget-componenent-header">
					<Loader/>
				</div>
			)			
		}
	}
}

export default WidgetLuxHeader;
