import React, { Component } from 'react';
import Switch from 'rc-switch';
import {DataContext} from '../../DataContext';
import Loader from '../Loader';


class BinarySwitch extends Component {

	static contextType = DataContext
	constructor() {
		super();
		this.state = { 
			checked: false, 
			disabled: false,
		};
		this.handleChange 	= this.handleChange.bind(this);
		this.handleLock 	= this.handleLock.bind(this);
	}
	
	handleLock() {
		this.context.toggleBinary({ga:this.props.lock});
	}
	handleChange(checked) {
		this.context.toggleBinary({ga:this.props.ga});
	}
	render() {
		const value = this.context.getNumericValue(this.props.statusDim, false)
		const lock = this.props.lock !== undefined ? true : false;
		const icon = (this.props.type&&this.props.type==='socket') ? 'icon-power-cord': 'icon-light-bulb'
		let disabled = (typeof this.context.getBinaryValue(this.props.ga)==="boolean") ? false : true;
		if(this.props.lock !== undefined && disabled === false && this.context.getBinaryValue(this.props.lock, false) === true){
			disabled = true;
		}
		return (<>
				{(this.props.statusDim && typeof value ==="number" && value !== 0) ? 
					<span className="switch-value" data-collapse-trigger>
						{this.context.getNumericValue(this.props.statusDim)} %
					</span> 
					: <></>
				}					
				{(this.props.lock !== undefined) ? 
					<span className="lock-wrapper" onClick={this.handleLock}>
						{( this.context.getBinaryValue(this.props.lock, false))
							? <i className={"icon-lock ico-on blue"}></i> 
							: <i className={"icon-lock-open ico-off"}></i>
						}
					</span> 
					: <></>
				}					
			
					<span className="icons">
						{(typeof this.context.getBinaryValue(this.props.ga)==="boolean") 
						? this.context.getBinaryValue(this.props.ga, false) 
							? <i className={icon+"-on ico-on"}></i> 
							: <i className={icon+"-off ico-off"}></i>
						: <Loader/>}
					</span>
				<Switch
					checked={this.context.getBinaryValue(this.props.ga, false)}
					onClick={this.handleChange}
					disabled={disabled}
					className="react-switch"
				/>		
			</>)
	}
}

export default BinarySwitch;