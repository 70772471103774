import React, { Component } from 'react';
import {DataContext} from '../../DataContext';
import Loader from '../Loader';

class WidgetIO extends Component {
	static contextType = DataContext
	constructor() {
		super();
		this.state = { 
			checked: false, 
			disabled: false,
		};
		this.handleChange = this.handleChange.bind(this);
	}
	
	handleChange(checked) {
		this.context.toggleBinary({ga:this.props.ga});
	}
	render() {
		let value = this.context.getBinaryValue(this.props.ga, false);
		const icon = (this.props.type&&this.props.type==='socket') ? 'icon-power-cord': 'icon-light-bulb'
		//const icon = 'icon-light-bulb';
		return (
		
			<div 
				className={`widget-io circle status-${value ? "i" : "o"}`}
				onClick={this.handleChange}>
					<span 
						className="icons"
						>
						{(typeof this.context.getBinaryValue(this.props.ga)==="boolean") 
						? value 
							? <i className={icon+"-on ico-off"}></i> 
							: <i className={icon+"-off ico-on"}></i>
						: <Loader/>}
					</span>
					<span className="label">{this.props.label}</span>

			</div>)
	}
}

export default WidgetIO;
