
import React, { Component } from 'react';
import BinaryButton from '../controller/BinaryButton';
import BareLightDimmer from '../controller/BareLightDimmer';
import CollapseContainer from '../ContainerCollapse';



import {DataContext} from '../../DataContext';

class MultiCard extends Component {
	static contextType = DataContext;

	renderComponents(components){
		if(components){
			return <>
				{components.map((item, i) => {
					if(item.type){
						switch(item.type){
							case 'light':
								if(item.statusDim){
									return (
										<div key={i} className="component component-dimmer">
											{ item.label && <div className="label label-dimmer">{item.label}</div>}
											<div className="bare-dimmer">
												<BinaryButton ga={item.io} value="false"><i className="icon-light-bulb-off"/></BinaryButton>
												<BareLightDimmer dim={item.statusDim} io={item.io} />
												<BinaryButton ga={item.io} value="true"><i className="icon-light-bulb-on"/></BinaryButton>
											</div>
										</div>
									);
								} else {
									return(
										<div key={i} className="component component-switch">
										
											<BinaryButton ga={item.io} value="false"><i className="icon-light-bulb-on"/>{ item.label && <span className="label label-switch">{item.label}</span>}</BinaryButton>
										</div>
									);
								}
							case 'default': return	(<span key={i}> {item.type} : {item.label}</span>) ;
							default: return	(<></>) ;
						}
					} else return (<></>);
				})}			
			</>
		}
	}


	
	render() {
		let collapse = this.props.components.length === 1 ? false : true;
		if( collapse)
		return(
			<CollapseContainer className={"card flex " + this.props.wrapperClass}>
				<div className="label" data-collapse-trigger>
					<i className={this.props.icon} />
					<span>{this.props.label}</span>
				</div>
				
					
				
				<div className="components"  data-collapse-target>	
					{this.renderComponents(this.props.components)}
				</div>				
			</CollapseContainer>
		);



		return(
			<div className={"card flex " + this.props.wrapperClass}>
				<div className="label">
					<i className={this.props.icon} />
					<span>{this.props.label}</span>
				</div>
										
				<div className="components">	
					{this.renderComponents(this.props.components)}
				</div>

			</div>
		);
		/*
		return (
			<div className={"box-wrapper "+this.props.wrapperClass}>
				<div className="box cover" style={this.props.style}>
					<div className="info">{this.context.getTempValue(this.props.temp, '', true)}</div>
					<div className="label-bottom">{this.props.label}</div>
					<div className="i-o-switch"><BinarySwitch ga={this.props.io}/></div>
				</div>
			</div>
		);
		*/
	}
}

export default MultiCard;
