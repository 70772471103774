
import React, { Component } from 'react';

class ContentTable extends Component {
	render() {
		return (
		    <div className="content-container">
				<div className="content-label base-padding-h">{this.props.label}</div>
				<div className="table">{this.props.children}</div>
		    </div>
		);
	}
}

export default ContentTable;
