
import React, { Component } from 'react';

class ContainerCollapse extends Component {
    constructor(props){
        super(props);
	    this.toggle = this.toggle.bind(this);
	    this.state = { collapse: false };
    }
	toggle() {
		this.setState(state => ({ collapse: !state.collapse }));
	}
	render() {
		var toggleClass = this.state.collapse ? ' in' : '';
		const childrenWithWrapperDiv = React.Children.map(this.props.children, child => {
			if(child.props["data-collapse-trigger"]){
				return (
					<div className={child.props.className} onClick={this.toggle}>{child.props.children}</div>
				);
			} else if(child.props["data-collapse-target"]){
				return (
					<div className={child.props.className + toggleClass}>
						{this.state.collapse ? <>{child.props.children}</> : <></>}
					</div>
				);
			} else {
				return (
					<>{child}</>
				);
			}
		});
		return (
		    <div className={this.props.className + toggleClass} >
				{childrenWithWrapperDiv}     
		    </div>
		);
	}
}
export default ContainerCollapse;
