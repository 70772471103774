
import React, { Component } from 'react';
import BinarySwitch from '../controller/BinarySwitch';
import {DataContext} from '../../DataContext';

class DashboardBox extends Component {
	static contextType = DataContext
	render() {
		
		
		return (
			<div className={"box-wrapper "+this.props.wrapperClass}>
				<div className="box cover" style={this.props.style}>
					<div className="info">{this.context.getTempValue(this.props.temp, '', true)}</div>
					<div className="label-bottom">{this.props.label}</div>
					<div className="i-o-switch"><BinarySwitch ga={this.props.io}/></div>
				</div>
			</div>
		);
	}
}

export default DashboardBox;
