import React, { Component } from 'react';
import {DataContext} from '../../DataContext';
import Loader from '../Loader';

class WidgetWeatherHeader extends Component {
	static contextType = DataContext
	constructor() {
		super();
		this.state = { 
			checked: false, 
			disabled: false,
		};
	}
//	componentDidMount() {
//		this.context.getWeather();
//	}	
	
	
	render() {
		if(this.context.weather.main){
			return (
				<div className="widget-weather-header widget-componenent-header text-right on">
					<div className="temperature value">{Math.floor(this.context.weather.main.temp)}°</div>
					<div className="description">{this.context.weather.weather[0].description}</div>
				</div>
			)
		} else {
			return (
				<div className="widget-weather-header widget-componenent-header">
					<Loader/>
				</div>
			)			
		}
	}
}

export default WidgetWeatherHeader;
