import React, { Component } from 'react';
import {DataContext} from '../../DataContext';
import CollapseContainer from '../ContainerCollapse';
import ModalContainer from '../ModalContainer';
import ChartTemperature from '../ChartTemperature';
import VerticalHeater from '../controller/VerticalHeater';
import Loader from '../Loader';

//import TemperatureChart from './TemperatureChart';


class RowTemperature extends Component {
	static contextType = DataContext
	render() {
		const heater = this.props.gaHeaterStatus;
		return (
			<CollapseContainer className="table-row ease-02">
				<div className="table-col" data-collapse-trigger>{this.props.label}</div>
				<div className="table-col text-right">
					
					{ heater ? 
						this.context.getNumericValue(this.props.gaHeaterStatus, false) !== false ? 
							<ModalContainer className="ease-02">
								<div className="text-right right padding-left-small flex v-center" data-modal-trigger>
									<div className="heater-small-widget left">
										{ this.context.getNumericValue(this.props.gaHeaterStatus, 0) === 0 ? '' :
											<div className="heater-status-icon small"><span style={{opacity:this.context.getNumericValue(this.props.gaHeaterStatus, 0)/100}}/></div>
										}
										<div className="room-current-temp">{this.context.getTempValue(this.props.gaHeaterSetpointRead, '--')}</div>		
									</div>
									<i className="icon-cog right"/>
								</div>
								<div className="full-width center" data-modal-content>
									<div className="flex-wrapper col">
										<h1 className="text-center">{this.props.label}</h1>
										<VerticalHeater currentTemp={this.props.ga} statusHeater={this.props.gaHeaterStatus} reader={this.props.gaHeaterSetpointRead} writer={this.props.gaHeaterSetpointWrite} />
										<div className="modal-status top left">
											<div className="heater-status flex v-center"><i className="icon-valve-1"/>
												{ this.context.getNumericValue(this.props.gaHeaterStatus, 0) === 0 ? '' :
													<div className="heater-status-icon">
														<span style={{opacity:this.context.getNumericValue(this.props.gaHeaterStatus, 0)/100}}/>
													</div>
												}
												{this.context.getNumericValue(this.props.gaHeaterStatus, '--')}%
											</div>
											<div className="room-current-temp"><i className="icon-temperature"/>{this.context.getTempValue(this.props.ga, '--')}</div>
										</div>
									</div>
								</div>
							</ModalContainer>
							: 	<div className="text-right right padding-left-small flex v-center disabled">
									<Loader/>
								</div>
						: ''
					}
					<span className="right" data-collapse-trigger>{this.context.getTempValue(this.props.ga, '--')}</span>
				</div>
				<div className="table-col-colspan ease-02 collapse" data-collapse-target>
					<ChartTemperature id={this.props.ga} ga={this.props.ga} setpoint={this.props.gaHeaterSetpointRead} />
				</div>
			</CollapseContainer>
        )
	}
}

export default RowTemperature
