import React, { Component } from 'react';

import ContentTable from '../components/layout/ContentTable';
import ContentGrid from '../components/layout/ContentGrid';
import DashboardBox from '../components/layout/DashboardBox';
// import DashboardDimBox from '../components/DashboardDimBox';
import RowTemperature from '../components/layout/RowTemperature';
import RowLight from '../components/layout/RowLight';
import Jal from '../components/controller/Jal';
import Slider from '../components/layout/Slider';
import WidgetIO from '../components/widgets/WidgetIO';
import MultiCard from '../components/layout/MultiCard';


// import DevMove from '../components/DevMove';

import {DataContext} from '../DataContext';


class Page extends Component {
	static contextType = DataContext;
	page = {};
    constructor(props){
        super(props);
	    this.loadData = this.loadData.bind(this);
	    this.state = { page: {} };
    }
	loadData(id){
		let page = this.context.page(id);
		this.setState(state => ({ page: page }));
		if(page) document.title = page.title;

	}    	
	componentDidUpdate(prevProps){
		if (this.props.match.params.id !== prevProps.match.params.id) 
			{ this.loadData(this.props.match.params.id); }
	}
	componentDidMount() {
		this.loadData(this.props.match.params.id);
	}
	
	renderContent(data){
		if(data.content){
			return <>
				{data.content.map((item, i) => {
					if(item.type){
						let children = item.content ? this.renderContent(item) : '';
						let props = item.props ? item.props : {};
						switch(item.type){
							case 'contentTable': 	return(<ContentTable key={i} {...props}>{children}</ContentTable>);
							case 'RowTemperature':	return(<RowTemperature key={i} {...props}/>);
							case 'RowLight':		return(<RowLight key={i} {...props}/>);
							case 'ContentGrid':		return(<ContentGrid key={i} {...props}>{children}</ContentGrid>);
							case 'DashboardBox':	return(<DashboardBox key={i} {...props} />);
							case 'MultiCard':		return(<MultiCard key={i} {...props} />);
//							case 'DashboardDimBox': return(<DashboardDimBox key={i} {...props} />);
							case 'Jal':				return(<Jal key={i} {...props} />);
							case 'Slider':			return(<Slider key={i} {...props}/>);
							case 'WidgetIO':		return(<WidgetIO key={i} {...props} />);
							default:				return	(<span key={i}> {item.type} : {item.label} {children}</span>) ;
						}
					} else return (<></>);
				})}			
			</>
		}
	}
	
	
	render() {
		if(this.state.page){
			return (
				<div className="body-content base-padding-v">
					<h1>{this.state.page.title}</h1>
					{this.renderContent(this.state.page)}
				</div>
			);
		} else {
			return (<></>);
		}
	}
}

export default Page;