
import React, { Component } from 'react';
import Modal from 'react-modal';
import nextId from "react-id-generator";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


Modal.setAppElement('#root')



class ModalContainer extends Component {
	targetElement = null;
	
	id = 'modal-' + nextId();
	constructor (props) {
		super(props);
		this.state = {
		  showModal: false
		};
		this.handleOpenModal = this.handleOpenModal.bind(this);
		this.handleCloseModal = this.handleCloseModal.bind(this);
				this.targetElement = document.querySelector('#'+this.id);

	}
  
	handleOpenModal () {
		this.setState({ showModal: true });
		disableBodyScroll(this.targetElement); 

	}
	
	handleCloseModal () {
		this.setState({ showModal: false });
		enableBodyScroll(this.targetElement); 

	}
	componentDidMount() {
		this.targetElement = document.querySelector('#'+this.id);
		this._isMounted = true;
	}  
	componentWillUnmount() {
		clearAllBodyScrollLocks();
		this._isMounted = false;

	}  


  render () {
	  
	const modalComponents = React.Children.map(this.props.children, child => {
		if(child.props["data-modal-trigger"]){
			return (
				<div  id={this.id} className={child.props.className} onClick={this.handleOpenModal}>{child.props.children}</div>
			);
		} else if(child.props["data-modal-content"]){
			return (
					<Modal 
				           isOpen={this.state.showModal}
				           contentLabel="onRequestClose Example"
				           onRequestClose={this.handleCloseModal}
				           className="modal-content"
				           overlayClassName="modal-overlay"
				           shouldCloseOnOverlayClick={true}
				        >
						<div id={this.id+"-content"} className="content">
							<>{child}</>
						</div>
						<div className="close" onClick={this.handleCloseModal}><span>&times;</span></div>
					</Modal>
			);
		} else {
			return (
				<>{child}</>
			);
		}
	});

	  
	  
	  
    return (
      <div className={this.props.className}>
		{modalComponents}
      </div>
    );
  }
}

export default ModalContainer;
