import React, { Component } from 'react';
import {DataContext} from '../../DataContext';
import Loader from '../Loader';
import ModalContainer from '../ModalContainer';
import VerticalJal from './VerticalJal';



class Jal extends Component {

	static contextType = DataContext
	constructor(props) {
		super(props);
		this.state = { 
			checked: false, 
			disabled: false,
		};
		this.jalStop 	= this.jalStop.bind(this);
		this.jalUp 		= this.jalUp.bind(this);
		this.jalDown 	= this.jalDown.bind(this);
		this.jalGotTo 	= this.jalGotTo.bind(this);
		console.log(this);
	}

	jalStop() {
		this.context.jalControler({ga:this.props.ga.position,op:'stop'});
	}
	jalUp() {
		this.context.jalControler({ga:this.props.ga.position,op:'up'});
	}
	jalDown() {
		this.context.jalControler({ga:this.props.ga.position,op:'down'});
	}
	jalGotTo(value = 50) {
		this.context.jalControler({ga:this.props.ga.position,op:'position', value: value});
	}

/*
up_down	"2/1/4"
start_reference	"2/1/5"
short_start_stop	"2/1/6"
absolute	"2/1/7"

				<hr/>

				<div>direction_current {this.context.getBinaryValue(this.props.ga.direction)? 'TRUE' : 'FALSE'}</div>
				<div>position_current {this.context.getNumericValue(this.props.ga.position)}</div>
				<div>position_top {this.context.getBinaryValue(this.props.ga.isTop) ? 'TRUE' : 'FALSE'}</div>
				<div>position_bottom {this.context.getBinaryValue(this.props.ga.isBottom)? 'TRUE' : 'FALSE'}</div>
				<div>drive_up {this.context.getBinaryValue(this.props.ga.activeUp)? 'TRUE' : 'FALSE'}</div>
				<div>drive_down {this.context.getBinaryValue(this.props.ga.activeDown)? 'TRUE' : 'FALSE'}</div>
		
direction_current	this.props.ga.direction
position_current	this.props.ga.position
position_top	this.props.ga.isTop
position_bottom	this.props.ga.isBottom
drive_up	this.props.ga.activeUp
drive_down	this.props.ga.activeDown		
*/
	
	render() {
		const positionPercent 	= this.context.getNumericValue(this.props.ga.position);
		const activeUp 			= this.context.getBinaryValue(this.props.ga.activeUp, null);
		const activeDown 		= this.context.getBinaryValue(this.props.ga.activeDown, null);
		const isTop 			= this.context.getBinaryValue(this.props.ga.isTop, null); 
		const isBottom 			= this.context.getBinaryValue(this.props.ga.isBottom, null); 

		const connected 		= (typeof activeUp==="boolean"&&typeof activeDown==="boolean") ? true : false;
		const active 			= ( (activeUp&&!isTop)||(activeDown&&!isBottom) ) ? true : false;
		
		
		let activeDownClass 	= (typeof activeUp === "boolean"&&activeUp) ? ' active-up' : '';
		let activeUpClass 		= (typeof activeDown === "boolean"&&activeDown) ? ' active-down' : '';
		let activeClass 		= ((typeof activeUp === "boolean"&&activeUp)||(typeof activeDown === "boolean"&&activeDown)) ? ' active' : '';
		let isTopClass 			= ((typeof isTop === "boolean"&&isTop)||(typeof isBottom === "boolean"&&isBottom)) ? ' top-bottom' : ' top-and-bottom';
		

		
		const btnCntr = (<>
				{(connected)?
					<div className={"jal-wrapper btn-wrapper" + activeClass + activeDownClass + activeUpClass + isTopClass}>
						{ ( active ) ? 
							<div className="btn btn-stop" onClick={this.jalStop}><i className="icon-pause"></i></div> : 
							<>
								{ (isBottom||(!isBottom&&!isTop)) ? 
									<div className="btn btn-up" onClick={this.jalUp}><i className="icon-up-dir-1"></i></div> : <></>
								}
								{ (isTop||(!isBottom&&!isTop)) ? 
									<div className="btn btn-down" onClick={this.jalDown}><i className="icon-down-dir-1"></i></div> : <></>
								}
							</>
						}
						
					</div>
				: <><Loader/></> } </>)
		return (
			<>
				<ModalContainer className="table-row ease-02">
					<div className="table-col" data-modal-trigger>{this.props.label}</div>
					<div className="table-col text-right" >
						{(typeof positionPercent ==="number"&&(!active))
							? <span className="switch-value" data-collapse-trigger>{positionPercent} %</span> : <></>}	
						
						{(typeof activeUp ==="boolean"&&(activeUp))
							? <span className="blinking"><i className="icon-up-dir-1"></i></span>: <></>}	
						{(typeof activeDown ==="boolean"&&(activeDown))
							? <span className="blinking"><i className="icon-down-dir-1"></i></span>: <></>}	
						
						{btnCntr}
					</div>
					
					
					<div className="flex-wrapper col" data-modal-content>
						<h1 className="text-center">{this.props.label}</h1>
						<VerticalJal {...this.props} />
					</div>
				</ModalContainer>
					


			</>
		)
	}

}

export default Jal;