import React, { Component } from 'react';
import {LineChart, Line, XAxis, YAxis, ResponsiveContainer, Text} from 'recharts';
import Moment from 'moment';
import {DataContext} from '../DataContext';
import ls from "local-storage";

class ChartTemperature extends Component {	
	static contextType = DataContext;

	constructor(props, context) {
		super(props, context);	
        this.state = {
            lineData: {},
            setpoint: this.props.setpoint ? this.context.getTempValue(this.props.setpoint, false) : false,
        };
    }

	componentDidMount() {
		
		
		var postData = {
			ga: this.props.ga,
			setpoint: this.props.setpoint,
			token: ls('token')
		}

	
		//this.context.getTemperature({ga:this.props.ga});


		fetch(process.env.REACT_APP_ADDRESS+":"+process.env.REACT_APP_PORT + '/api/statistics/temperature', {
			crossDomain:true,
			method: 'POST', 
			headers : {'Content-Type': 'application/json'},
			body: JSON.stringify(postData),
		})
		.then(res => res.json())
		.then(data => this.setState({ lineData: data}))
		.catch((err)=>console.log(err));
		
	}
	render() {
		let setpointLegend = <></>;
		let setpointLine = <></>;
		let refernceLine = <></>;
		if (this.props.setpoint && this.context.getNumericValue(this.props.setpoint, false)) {
			setpointLegend =	<div className="text-center chart-legend"> Aktuelle Werte<br/><small>Soll: {this.context.getTempValue(this.props.setpoint, '--')} | Ist: {this.context.getTempValue(this.props.ga, '--')} | Außen: {this.context.weather.main.temp}°</small></div> 
			setpointLine =	<Line 
							connectNulls 
							type="step" 
							dataKey="setpoint" 
							stroke="rgba(255,255,255,.2)" 
							strokeDasharray="7 2" 	
							dot={false}
					//		labelFormatter={function(value){return value + " Uhr"}}
							name="setpoint"
						/>;
			/*			
			refernceLine =	<ReferenceLine 
							y={this.context.getNumericValue(this.props.setpoint, false)}
							stroke="rgba(255,255,255,0)" 
							strokeDasharray="7 2" 		
							label={{
								value: "Soll:" + this.context.getTempValue(this.props.setpoint, false),
								angle: 0, 
								offset: -55, 
							//	position:'insideTopLeft', 
								position:'right', 
								style:{fill:'#666',fontSize:'0.8em'} }} />;
			*/
		}

		if(this.state.lineData){
			const data = Object.values(this.state.lineData);
			if(data.length!==0){
			return (
				<>
				{setpointLegend}
				<div style={{ width: '100%', height: 210 }}>
					<ResponsiveContainer>					
						<LineChart
						    width={200}
						    height={900}
						    data={data}
						    margin={{top: 25, right: 0, left: 0, bottom: 25,}} >
						    <XAxis 
						    	padding={{ top: 0, bottom: 0, right: 0 ,left:20}}
						    	dataKey="ts" 
						    	interval="preserveStartEnd" 
						    	minTickGap={2}
						    	scale="time"
						    	domain={['dataMin - 90000000', 'dataMax + 90000000']}
						    	tick={{fill:'#666',fontSize:'0.8em'}}
								tickFormatter={function(value){return (Moment(value).format('HH:mm')) + " Uhr"}}
						    	label={{ 
							    	angle: -90, 
							    	position: 'insideLeft', 
							    	style:{fill:'#666',
							    	fontSize:'0.8em'}, 
									offset:20
									}} />
						    <YAxis
						    	domain={['dataMin - 15', 'dataMax + 5']}
						    	hide={true} />
							{refernceLine}
							{setpointLine}
						    	
							<Line 
								connectNulls type="monotone" 
								dataKey={this.props.ga} 
								stroke="#FFFFFF" 
								dot={false}
								labelFormatter={function(value){return value + " Uhr"}}
								name="name"
								label={function(value){
									if (value.index%2 === 0) return <></>;
									var label = parseFloat(Math.round(value.value * 10) / 10) + '°';
									return <Text x={value.x-40} style={{fill:'#CCC',fontSize:'0.8em'}} y={value.y-10} >{label}</Text>}} />
						
							<Line 
								connectNulls type="monotone" 
								dataKey="owm" 
								stroke="rgba(255,255,255,0.2)"
								dot={false}
								label={function(value){
									if (value.index%2 === 0) return <></>;
									var label = parseFloat(Math.round(value.value * 10) / 10) + '°';
									return <Text x={value.x-30} style={{fill:'#666',fontSize:'0.6em'}} y={value.y+20} >{label}</Text>}} />
						</LineChart>
					
					</ResponsiveContainer>
				</div>
				</>			
			);
			} else {
				return (<div>no data</div>);
			}
		} else {
			return (<div>no data</div>);
		}
	}
}



/*

*/

export default ChartTemperature;
