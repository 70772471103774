import React from 'react'
import io from "socket.io-client";
import ls from "local-storage"

export const DataContext = React.createContext({})



const reducer = (state, action) => {
  if (action.type === "TOGGLE") {
//    return { ...state, isADuck: !state.isADuck };
  }
};

/*************************************************
 *
 *
 *
 * 					Data Provider
 *
 *
 *
 *************************************************/

export class DataProvider extends React.Component {

    /*************************************************
     * 
     * I/O Socket Stuff
     *
     *************************************************/
	socket = io(process.env.REACT_APP_ADDRESS+":"+process.env.REACT_APP_PORT, {
		//withCredentials: true,
		withCredentials: false,
//		extraHeaders: {
//			"my-custom-header": "abcd"
//		}		
	});

	constructor(props){
        super(props);

	    /*
	     * Connections
	     */
		this.socket.on('connect', function(){
			this.setState({ socketConnection: true });
			if(ls('token')){
				this.socket.emit('TOKEN', {token:ls('token')});
			}
		}.bind(this));		
		this.socket.on('disconnect', function(){
			this.setState({ socketConnection: false });
		}.bind(this));		
 

	    /*
	     * Auth
	     */
        this.socket.on('SERVERVERSION', function(data){
	        if(data.version){
				this.serverVersion = data.version;
	        }
		}.bind(this));
        this.socket.on('AUTH', function(data){
	        if(data.token){
		        ls('token', data.token);
				ls('user', data.user);
				ls('menu', data.menu);
				ls('pages', data.pages);
		        this.setState({ user: data.user });
		        this.setState({ menu: data.menu });
		        this.setState({ pages: data.pages });
	        }
		}.bind(this));
		
        this.socket.on('ACCESS', function(data){
	        if(data){
		        this.setState({ isAuth: true });
				this.socket.emit('GA_STATES', {token:ls('token')});
				this.socket.emit('WEATHER', {token:ls('token')});
	        } else {
		        ls.remove('token');
		        ls.remove('user');
		        ls.remove('menu');
		        ls.remove('pages');
		        this.setState({ isAuth: false });
		        this.setState({ ga: {} });
	        }
		}.bind(this));	
		
		
	    /*
	     * Data
	     */		
        this.socket.on('GA_STATECHANGE', function(data){
			let newGa = {...this.state.ga}
			newGa[data.address] = data.value;
			this.setState({ ga: newGa });
		}.bind(this));
        
        this.socket.on('GA_STATES', function(data){
			this.setState({ ga: data });
		}.bind(this));
		
		
        this.socket.on('WEATHER', function(data){
	        if(data.dt !== this.state.weather.dt){
				this.setState({ weather: data });
	        }
	        
	        
		}.bind(this));
        
        this.socket.on('SERVERVERSION', function(data){
	        this.setState({ serverVersion: data });
		}.bind(this));
		
		
	    /*
	     * Content
	     */

        this.socket.on('PAGES', function(data){
	        ls('pages',data);
			this.setState({ pages: data });
		}.bind(this));		
		
		
    }

    /*************************************************
     * 
     * Global States & Functions
     *
     *************************************************/


	state = {
		clientVersion: '5.1.0-dev',
		serverVersion: '-',
		socket: this.socket,
		menu:ls('menu'),
		isAuth: false,
		socketConnection:false,
		ga:{},
		weather:{},
		pages:ls('pages'),
		theme:'default',
		user:ls('user'),
		sendAuth: (data) => {
			this.socket.emit('SEND_AUTH', data);
	    },
		dispatch: action => {
	      this.setState(state => reducer(state, action));
	    },


	    /*
	     *  Data Emiter / Writer
	     */
	    getGa: (data) => {
			data.token = ls('token');
			this.socket.emit('GA_READ', data);
	    },
	    setGa: (data) => {
			data.token = ls('token');
			this.socket.emit('GA_WRITE', data);
	    },
	    setPercentAbsolute: (data) => {
			data.token = ls('token');
			this.socket.emit('GA_PERCENT_ABSOLUTE', data);
	    },
	    toggleBinary: (data) => {
			data.token = ls('token');
		    this.socket.emit('GA_TOGGLE_BINARY_STATE', data);
	    },
	    setBinary: (data) => {
			data.token = ls('token');
		    this.socket.emit('GA_SET_BINARY_STATE', data);
	    },
	    jalControler: (data) => {
			data.token = ls('token');
			console.log(data);
		    this.socket.emit('GA_JAL_CONTROL', data);
	    },
	    /*
	    getTemperature: (data) => {
			data.token = ls('token');
			this.socket.emit('STATS_TEMPERATURE', data);
	    },
	    */
	    getWeather: (data = {}) => {
			data.token = ls('token');
			this.socket.emit('WEATHER', data);
	    },
	    
	    
	    /*
	     *  Helper
	     */	    
	    
	    ls: (key) => {
		    return ls(key);
	    },
	    page: (key) => {
		    if(this.state.pages&&this.state.pages[key]){
				return this.state.pages[key];		    
		    } else {
    		    this.socket.emit('PAGE', {token:ls('token'), id:key})
			    return {title:'Not Found'}
		    }
	    },
	   
	   
	    /*
	     *  Common
	     */	    
	    
	    getServerVersion: () => {
			this.socket.emit('SERVERVERSION', {token:ls('token')});
	    },
	    /*
	     *  User
	     */	    
	    
	    logout: () => {
	        ls.remove('token');
	        ls.remove('user');
	        ls.remove('menu');
	        ls.remove('pages');
	        this.setState({ isAuth: false });
	        this.setState({ ga: {} });
	    },
		changePassword: (data) => {
			data.token = ls('token');
		    this.socket.emit('USER_CHANGE_PASSWORD', data);
	    },
	    
	    
	    /*
	     * Formatter / Getter Helper
	     */
		getGaValue: (ga, def = 'Keine Verbindung') => {
			if(this.state.ga[ga]!==undefined){
				if(typeof this.state.ga[ga] === 'object' && this.state.ga[ga] !== null)  return this.state.ga[ga].value;
				if(this.state.ga[ga]===null) return '--';
				return this.state.ga[ga];
			} else {
				return def;
			}
	    },
		getBinaryValue: (ga, def = 'Keine Verbindung') => {
			if(this.state.ga[ga]!==undefined){
				if(typeof this.state.ga[ga]==="boolean"){
					return this.state.ga[ga];
				} else if(typeof this.state.ga[ga] === 'object' && this.state.ga[ga] !== null){
					if(this.state.ga[ga].value!==undefined && typeof this.state.ga[ga].value==="boolean"){
						return this.state.ga[ga].value;
					}
				}
			}
			return def;
	//		return (this.state.ga[ga]!==undefined && typeof this.state.ga[ga]==="boolean") ? this.state.ga[ga] : def;
	    },
		getNumericValue: (ga, def = 'Keine Verbindung') => {
	
			if(this.state.ga[ga]!==undefined){
				if(typeof this.state.ga[ga]==="number"){
					return this.state.ga[ga];
				} else if(typeof this.state.ga[ga] === 'object' && this.state.ga[ga] !== null){
					if(this.state.ga[ga].value!==undefined && typeof this.state.ga[ga].value==="number"){
						return this.state.ga[ga].value;
					}
				}
			}	
			return def;
			
			return (this.state.ga[ga]!==undefined && typeof this.state.ga[ga]==="number") ? this.state.ga[ga] : def;
	    },
		getTempValue: (ga, def = 'Keine Verbindung',format = false) => {
			if(this.state.ga[ga]!==undefined){
				if(this.state.ga[ga]===null||this.state.ga[ga]===0) return '–';
				let result = this.state.ga[ga];
				if(format) result = Math.floor(result);
				return result + "°" ;
			} else {
				return def;
			}
	    }
	};
	
    /*************************************************
	 *
     * Render function
     *
     *************************************************/
     	
	render() {
		const { children } = this.props;
		return ( 
			<DataContext.Provider value={this.state}>
				{children}
			</DataContext.Provider>
		);
	}
}
export const DataConsumer = DataContext.Consumer
export default DataContext