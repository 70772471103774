import React, { Component } from 'react';
import {DataContext} from '../../DataContext';
import Loader from '../Loader';
import { Moon } from "lunarphase-js";


class WidgetLunarHeader extends Component {
	static contextType = DataContext
	
	
	getTranslation(key){
		const translation = {
			"New"				: "Neumond",
			"Waxing Crescent"	: "Zunehmender Sichelmond",
			"First Quarter"		: "Zunehmender Halbmond", // Erstes Viertel",
			"Waxing Gibbous"	: "Zunehmender Mond",
			"Full" 				: "Voll",
			"Waning Gibbous"	: "Abnehmender Mond",
			"Last Quarter" 		: "Abnehmender Halbmond", // Letztes Viertel",
			"Waning Crescent"	: "Abnehmender Sichelmond",
		}
		if(translation.hasOwnProperty(key)){
			return translation[key];
		}
		return key;
	}
	
	constructor() {
		super();
		this.state = { 
			checked: false, 
			disabled: false,
		};

	}
	render() {
		const phase    = this.getTranslation(Moon.lunarPhase());
		const percent  = Math.round((Moon.lunarAgePercent() + Number.EPSILON) * 100) / 100
		const isWaxing = Moon.isWaxing();
		if(phase){
			return (
				<div className="widget-lunar-header widget-componenent-header text-right on">
					<span className="icon">{Moon.lunarPhaseEmoji()}</span>
					<span className="value">{phase}</span>
					<span className="description">{isWaxing ? <>+</>:<>-</>} {percent}%</span>
				</div>
			)
		} else {
			return (<></>)			
		}
	}
}

export default WidgetLunarHeader;
