import React, { Component } from 'react';
import {DataContext} from '../../DataContext';
import Loader from '../Loader';


class BinaryButton extends Component {

	static contextType = DataContext
	constructor() {
		super();
		this.state = { 
			disabled: false,
		};
		this.handleClick = this.handleClick.bind(this);
	}
	
	handleClick() {
		if(this.props.value){
			this.context.setBinary({ga:this.props.ga, value: this.props.value === 'true' ? true : false});
		//	this.context.setGa({ga:this.props.ga, value: true});
		} else{
			this.context.toggleBinary({ga:this.props.ga});
		}
	}
	render() {
		let active = this.context.getBinaryValue(this.props.ga);
		if (typeof active !== "boolean") return <Loader/>;
		return (
		<div className={`binary-button ${active ? "ico-on" : "ico-off"}`} onClick={() => {this.handleClick()}}>
			<div className="click-trigger" >
			{this.props.children}
			</div>
		</div>)
	}
}

export default BinaryButton;